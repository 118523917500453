import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'propertyFilter'
})
export class PropertyFilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: any): any[] {

    if (!items) return [];
    if (!field) return items;

    return items.filter(it => it[field] == value);
  }
}
