import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setLinkUnit'
})
export class SetLinkUnitPipe implements PipeTransform {
  transform(value: string, unitLink: string = ''): string {
    if(!value) value = 'site/<unit>/#';
    return value.replace('<unit>', unitLink);
   }
  }
