<div class="item" *ngIf="!useSpinner">
  <div style="display: flex;">
    <div class="skeleton-loader sk-square"></div>
    <div class="skeleton-loader sk-square"></div>
    <div class="skeleton-loader sk-square"></div>
    <div class="skeleton-loader sk-square"></div>
  </div>
  <div>
    <div class="skeleton-loader sk-rectangle "></div>
    <div class="skeleton-loader sk-rectangle "></div>
    <div class="skeleton-loader sk-rectangle "></div>
    <div class="skeleton-loader sk-rectangle "></div>
  </div>
  <!-- <ngx-skeleton-loader count="5" appearance="circle" [theme]="{
      width: '70px',
      height: '70px',
      'border-radius': '10px'
    }">
  </ngx-skeleton-loader>
  <ngx-skeleton-loader count="5" [theme]="{ 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader> -->
</div>

<div *ngIf="useSpinner" class="flex flex-column align-content-center justify-content-center" style="height: calc(100vh); position: fixed; left: 0; right: 0; bottom: 0; top: 0;">
  <p-progressSpinner class="text-center"></p-progressSpinner>
</div>
